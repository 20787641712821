import '@babel/polyfill';
import axios from "axios";
import envAPI from "src/api/config";
import { apiIntercept } from "src/api";
import { encodeParams } from 'src/libs/utils';
import { message } from 'antd';
const DEFAULT_ERROR_MESSAGE = "Something went wrong"

class HttpClient {
  // factory
  static create(baseUrl, options) {
    const axiosInstance = axios.create({ 
      ...options,
      baseURL: baseUrl,
    });
    return new HttpClient(axiosInstance);
  }

  // private fields
  axios = null;

  constructor(axiosInstance) {
    this.axios = axiosInstance;
  }

  async get(path, params) {
    
    try {
      let resp = null;
      if (params) {
        /**
         * axios get params 默认处理空格变+，后端不解析+号，需要手动处理params
         * 也不能处理params之后，处理之后的params，axios也会再次处理，%20 -> %2520, 后端无法解析
         */
        const _params = encodeParams(params)
        resp = await this.axios.get(`${path}?${_params}`);
      } else {
        resp = await this.axios.get(path);
      }
      return resp.data;
      // resolve(resp.data);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async fetch(path, params) {
    try {
      let resp = null;
      if (params) {
        resp = await fetch(path, { params: params });
      } else {
        resp = await fetch(path);
      }
      
      return resp;
      // resolve(resp.data);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async post(path, data, config = {}) {
    try {
      const resp = await this.axios.post(path, data, config);

      return resp.data;
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async patch(path, data) {
    try {
      const resp = await this.axios.patch(path, data);
      return resp.data;
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async delete(path) {
    try {
      const resp = await this.axios.delete(path);
      return resp;
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  handleError(err) {
    if (err.response) {
      const errorMessage = err.response.data?.message || err.response.data?.msg;
      console.error(!!errorMessage ? errorMessage : DEFAULT_ERROR_MESSAGE)
    } else {
      console.error(DEFAULT_ERROR_MESSAGE);
    }
  }
  
}

export default HttpClient;

const http = HttpClient.create(envAPI.cedarApiUrl);
// 启动拦截，添加token
apiIntercept(http);

export {
  http
}