/**
 * @author tiandd
 * @module utils 封装
 * @date 2021-07-02
 */
import {  notification } from 'antd';
import LIVR from "livr";
import moment from 'moment';
import { loginApi } from "../api/Auths";
import envAPI from "../api/config";
import { CURRENT_YEAR, DATE_FORMAT } from './configData';
/**
 * @错误 信息提示：notification
**/
let errTimer = null;
const msg = (type, context) => {
  clearTimeout(errTimer);
  errTimer = setTimeout(() => {
    notification[type]({
      message: context,
      description: '',
    });
  }, 500);
}

/**
 * 获取url最后一个斜杠后的字符串
 */
export const getLastUrlSub = (url) => {
  // eslint-disable-next-line no-useless-escape
  const index = url.lastIndexOf('\/');
  const str = url.substring(index + 1, url.length);
  return str;
};

export const filterXSS = (e) => {
  const unescape = window.unescape || window.decodeURI;
  if (!e) return e;
  for (; e !== unescape(e);) e = unescape(e);
  for (let r = ['<', '>', '\'', '"', '%3c', '%3e', '%27', '%22', '%253c', '%253e', '%2527', '%2522'], n = ['&#x3c;', '&#x3e;', '&#x27;', '&#x22;', '%26%23x3c%3B', '%26%23x3e%3B', '%26%23x27%3B', '%26%23x22%3B', '%2526%2523x3c%253B', '%2526%2523x3e%253B', '%2526%2523x27%253B', '%2526%2523x22%253B'], a = 0; a < r.length; a++) e = e.replace(new RegExp(r[a], 'gi'), n[a]);
  return e;
};

export const getUrlQuery = (name) => {
  // eslint-disable-next-line no-useless-escape
  const result = location.search.match(new RegExp(`[\?\&]${name}=([^\&\/]+)`, 'i'));
  if (result === null || result.length < 1) {
    return '';
  }
  return filterXSS(result[1]);
};

export const getUrlParams = (url, name) => {
  // eslint-disable-next-line no-useless-escape
  const result = url.match(new RegExp(`[\?\&]${name}=([^\&\/]+)`, 'i'));
  if (result === null || result.length < 1) {
    return '';
  }
  return decodeURIComponent(result[1]) || '';
};

/**
 * @删除 url search中的参数：?locale=zh-CN&type=edit
**/
export const removeUrlParam = (search, name) => {
  // 生成一个正则匹配 search里的 参数 /locale=[^&]*&?/
  const exp = new RegExp(`${name}=[^&]*&?`)
  let _s = search.replace(exp, '');
  if (_s.substr(-1) === '&') _s = _s.substring(0, _s.length - 1);
  // search无参数时，返回空即可
  if (_s === '?') return ''
  else return _s;

}

// 清除授权缓存
export function clearAuthInfo() {
  $session.remove('view.layout.own.context');
  $session.remove('view.layout.selected.tenant');
  $session.remove('view.layout.auth.tenants');
  
}

// 清除缓存
export function clearAccess() {
  $storage.remove('view.common.region');
  $storage.remove('view.login.token');
  $storage.remove('accessToken');
  $storage.remove('view.roles.page.type');
  $storage.remove('view.login.session');
  $storage.remove('view.login.expire.time');
  $storage.remove('view.login.userInfo');
  $storage.remove('view.login.refreshToken');
  $storage.remove('refreshToken');
  $storage.remove('view.login.complete');
  $storage.remove('view.login.move.from');
  $storage.remove('layout.menu.active');
  
  $session.remove('view.org.page');

  $session.remove('view.customer.page');
  $session.remove('view.customer.search');
  $session.remove('view.customer.region');

  $session.remove('view.workspace.page');
  $session.remove('view.workspace.search');
  $session.remove('view.customer.workspace.page');
  $session.remove('view.customer.workspace.search');
  
  clearAuthInfo();

  localStorage.removeItem('x-access-token');
  localStorage.removeItem('x-refresh-token');
  localStorage.removeItem('x-expire-time');
}

/**
 * 退出登录
 */
export function logout() {
  clearAccess();
  window.location.href = `${envAPI.cedarApiUrl}/gas/api/v1alpha1/sso/logout?app=${encodeURIComponent(
    document.location.origin + document.location.pathname
  )}`;
}

// cookie
export const cookieEnabled = navigator.cookieEnabled;

// token
export function setToken(token) {
  localStorage.setItem('view.login.token', token);
  localStorage.setItem('x-access-token', token);
  localStorage.setItem('accessToken', token);
}

export function setRefreshToken(refreshToken) {
  localStorage.setItem('view.login.refreshToken', refreshToken);
  localStorage.setItem('x-refresh-token', refreshToken);
  localStorage.setItem('refreshToken', refreshToken);
}

export function getToken() {
  const token = localStorage.getItem('view.login.token');
  return token || '';
}

export function getRefreshToken() {
  return localStorage.getItem('view.login.refreshToken');
}

export function removeToken() {
  localStorage.removeItem('view.login.token');
  localStorage.removeItem('accessToken');
}

export function getExpireTime() {
  return localStorage.getItem('view.login.expire.time');
}

export function setExpireTime(time) {
  localStorage.setItem('view.login.expire.time', time);
  localStorage.setItem('x-expire-time', time);
}

export function getCookie(name) {
  const cName = name;
  if (document.cookie.length > 0) {
    let cStart = document.cookie.indexOf(`${cName}=`);
    if (cStart !== -1) {
      cStart = cStart + cName.length + 1;
      let cEnd = document.cookie.indexOf(';', cStart);
      if (cEnd === -1) cEnd = document.cookie.length;
      return unescape(document.cookie.substring(cStart, cEnd));
    }
  }
  return '';
}

export function deleteCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

// 刷新token
let tokenTimer = null;
export async function refreshToken(href) {
  clearTimeout(tokenTimer);
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return;
  }
  tokenTimer = setTimeout(async () => {
    try {
      const res = await loginApi.refreshToken();
      const { success, data } = res;
      if (success) {
        const { accessToken } = data;
        if (accessToken) saveAccessToken(data);
        msg("warn", lang.访问令牌已更新);
        // 获取新token后，刷新页面
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      msg("warn", lang.登录过期提示);
      setTimeout(() => {
        logout();
      }, 3000);
    }
  }, 200);
}

export function saveAccessToken(cred) {
  if (!cred) {
    return;
  }
  setToken(cred.accessToken);
  setRefreshToken(cred.refreshToken);
  setExpireTime(new Date(cred.expireAt).toISOString());
}

export function isAccessTokenExpired() {
  const expireTime = getExpireTime();
  return !!expireTime && new Date().getTime() > new Date(expireTime).getTime();
}

// 根据 ticket 获取token
export const getTokenBySSO = async () => {
  
  const _href = window.location.href;
  const _search = window.location.search;
//   const _ticket = getUrlParams(_search, 'sso_ticket');
  const ssoTickets = new URLSearchParams(_search).getAll('sso_ticket');
  let _ticket = ssoTickets[ssoTickets.length - 1];

  const _redirect = getUrlParams(_search, 'redirect');
  const _mode = getUrlParams(_search, 'mode');
  const _route = _href.indexOf('#') !== -1 ? _href.split('#')[1] : '/app/center/home';

  // 第三方应用重定向到此页面时，清除home域名的缓存，防止自动登录到应用中心
  if (_redirect) {
    // 清除缓存
    clearAccess();
    return
  } else if (_mode) {
    // 清除缓存
    clearAccess();
    return
  } else if (_ticket) {
    await loginApi.getTokenByTicket(_ticket).then(res => {
      // 保存token
      const { data, success } = res;
      if (success) {
        const { accessToken } = data;
        if (accessToken) saveAccessToken(data);
        window.history.pushState({}, 'GS-Application', `${envAPI.homeUrl}#${_route}`)
      }
    }).catch(err => {
      // 退出登录
      logout();
    });
  } else {
    // 其他非需要登录的页面
    return
  }
}

export function ssoLogin() {
  const _href = window.location.href;
  const _search = window.location.search;
  const ssoRedirects = new URLSearchParams(_search).getAll('redirect');
  const redirect = ssoRedirects[ssoRedirects.length - 1] || window.location.origin + window.location.pathname;
  // 子站点 退出到 ：`https://bot.test.gs-robot.com/gas/api/v1alpha1/sso/login?app=${encodeURIComponent(app_url)}`
  window.location.href = `${envAPI.cedarApiUrl}/gas/api/v1alpha1/sso/login?app=${encodeURIComponent(redirect)}`
  // else window.location.href = `${envAPI.cedarApiUrl}/gas/api/v1alpha1/sso/login?app=${encodeURIComponent(envAPI.homeUrl)}`
}

/**
 * @密码格式 最少8位，包含大写字母、小写字母和数字
 * @正则使用 !REG_PASSWORD.test(value) 代表格式不正确
**/  
const REG_PASSWORD = /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/;

/**
 * @邮箱格式 
 * @正则使用 !REG_EMAIL.test(email) 代表格式不正确
**/
const REG_EMAIL = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

/**
 * @项目编号 格式 只能由字母和数字组成
 * @正则使用 !REG_GS_WKSPACE_NO.test(no) 代表格式不正确 CURRENT_YEAR
 * /^(ZDKH|KH)([2021-2025]*)\d{7}$/
 * 高仙租户：必须以ZCKH或者KH开头；仅支持ZCKH/KH+年份+7位数字的组合（年份仅支持2021含2021之后的年份）
**/
const REG_GS_WKSPACE_NO = /(^ZDKH|KH)([2-9][0-9]{3})([0-9]{7}$)/;
/**
 * 独立租户：项目编码，仅支持字母+数字的组合；长度不超过15位 (位数再页面控制)
**/
const REG_WKSPACE_NO = /^.*(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]+$/;
/**
 * 独立租户：UDESK项目编码，ZDKH+11数字的组合
**/
const REG_UDESK_WKSPACE_NO = /(^ZDKH)([0-9]{11}$)/;

/**
 * @客户编号 格式 只能由字母和数字组成
 * @正则使用 !REG_GS_CUS_NO.test(no) 代表格式不正确
 * 高仙租户：必须以CUS开头；仅支持CUS+年份+5位数字的组合（年份仅支持2021含2021之后的年份）
**/
const REG_GS_CUS_NO = /(^CUS)([2-9][0-9]{3})([0-9]{5}$)/;
/**
 * 独立租户：客户编码，仅支持字母+数字的组合；长度不超过12位 (位数再页面控制)
**/
const REG_CUS_NO = /^.*(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]+$/;
/**
 * CRM：客户编码，必须以CUS开头；CUS+9位数字
**/
const REG_CRM_CUS_NO = /(^CUS)([0-9]{9}$)/;

/**
 * 高仙邮箱，以gs-robot.com || gausium.com结尾
**/
export const REG_GAUSSIAN_EMAIL = /(@gs-robot\.com|@gausium\.com)$/;

/**
 * 1、验证表单
 * 2、通过后，-> {status: true, data: {}}
 * 3、不通过，-> {status: false, data: {}}
 */
export const verifyFormData = (value, rules) => {
  const validator = new LIVR.Validator(rules);
  validator.registerRules({ "gs_strong_password": gsStrongPassword });
  validator.registerRules({ "gs_format_phone": gsPhone });
  validator.registerRules({ "gs_format_username": gsUsername });
  validator.registerRules({ "gs_format_areaCode": gsAreaCode });
  // 验证数据
  const validData = validator.validate(value);
  if (validData) {
    // // 验证通过
    // console.log('success ====', validData);
    return {
      status: true,
      data: validData
    }
  } else {
    // 验证失败
    // console.log('error ====', validator.getErrors())
    return {
      status: false,
      data: validator.getErrors()
    }
  }
};

// gs密码规则
function gsStrongPassword() {
  return value => {
    // We do not validate empty fields. We have "required" rule for this purpose
    // (?=.*[!@#$%^&*? ])
    if (value === undefined || value === null || value === '') return;
    // const reg = /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/;
    // return error message
    if (!REG_PASSWORD.test(value)) return 'NOT_STRONG';
  };
}

// gs手机国际码规则
function gsAreaCode() {
  return value => {
    // We do not validate empty fields. We have "required" rule for this purpose
    if (value === undefined || value === null || value === '') return;
    const reg = /^\d+$/;
    // return error message
    if (!reg.test(value)) return 'NOT_FORMAT';
  };
}
// gs手机号规则
function gsPhone() {
  return value => {
    // We do not validate empty fields. We have "required" rule for this purpose
    if (value === undefined || value === null || value === '') return;
    const reg = /^\d+$/;
    // return error message
    if (!reg.test(value)) return 'NOT_FORMAT';
  };
}
// gs登录账号规则: 只能有字母、数字、下划线
function gsUsername() {
  return value => {
    // We do not validate empty fields. We have "required" rule for this purpose
    if (value === undefined || value === null || value === '') return;
    const reg1 = /^\w+$/;
    const reg2 = /^\d+$/;
    // return error message
    if (!reg1.test(value) || reg2.test(value)) return 'NOT_FORMAT';
  };
}

export const transValid = (type, vValue) => {
  switch (type) {
    // 隐私信息存储区域
    case 'privacyRegion':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '隐私区域不能为空';

          default:
            return vValue;
        }
      }
    // 登录账号
    case 'username':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '登录帐号不能为空';

          case 'NOT_FORMAT':
            return '登录帐号格式不正确';

          default:
            return vValue;
        }
      }
    // 昵称
    case 'displayName':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '昵称不能为空';

          default:
            return vValue;
        }
      }

    // 语言
    case 'locale':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '语言不能为空';

          default:
            return vValue;
        }
      }

    // password
    case 'password':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '密码不能为空';

          case 'TOO_SHORT':
            return '密码长度不小于8位';

          case 'NOT_STRONG':
            return '密码格式不正确';

          default:
            return vValue;
        }
      }

    // confirmedPassword
    case 'confirmedPassword':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '确认密码不能为空';

          case 'FIELDS_NOT_EQUAL':
            return '两次输入密码不一致';

          default:
            return vValue;
        }
      }

    // email
    case 'email':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '邮箱不能为空';

          case 'WRONG_EMAIL':
            return '邮箱格式不正确';

          default:
            return vValue;
        }
      }

    // 国际编码
    case 'areaCode':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '手机国际码不能为空';
          
          case 'NOT_FORMAT':
            return '手机国际码格式不正确';

          default:
            return vValue;
        }
      }

    // 手机号
    case 'phoneNumber':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '联系方式不能为空';

          case 'TOO_LONG':
            return '字符长度不超过';

          case 'NOT_FORMAT':
            return '只能输入数字';

          default:
            return vValue;
        }
      }

    // 验证码：手机或邮箱
    case 'verificationCode':
      {
        switch (vValue) {
          case 'REQUIRED':
            return '验证码不能为空';

          default:
            return vValue;
        }
      }

    default:
      return '';
  }
}
/**
 * @密码格式 最少8位，包含大写字母、小写字母和数字
 * @校验 强度是否合格
 * @返回 true: 合格 / false: 不合格
**/
export function isPasswordStrong(value) {
  if (!REG_PASSWORD.test(value)) return false
  else return true;
}
/**
 * @帐号规则 只能有字母、数字、下划线 & 不能纯数字
 * @校验 格式是否合格
 * @返回 true: 合格 / false: 不合格
**/
export function isUsernameReg(value) {
  const reg1 = /^\w+$/;
  const reg2 = /^\d+$/;
  if (!reg1.test(value) || reg2.test(value)) return false
  else return true;
}

/**
 * @国际码规则 只能数字
 * @校验 格式是否合格
 * @返回 true: 合格 / false: 不合格
**/
export function isAreaCodeReg(value) {
  const reg = /^\d+$/;
  if (!reg.test(value)) return false
  else return true;
}

/**
 * @手机号规则 只能数字
 * @校验 格式是否合格
 * @返回 true: 合格 / false: 不合格
**/
export function isPhoneNumberReg(value) {
  const reg = /^\d+$/;
  if (!reg.test(value)) return false
  else return true;
}

/**
 * @邮箱规则
 * @校验 格式是否合格
 * @返回 true: 合格 / false: 不合格
**/
export function isEmailReg(value) {
  if (!REG_EMAIL.test(value)) return false
  else return true;
}

/**
 * @高仙 【客户编号】规则
 * @校验 格式是否合格，只能由字母和数字组成
 * @返回 true: 合格 / false: 不合格
**/
export function isGSCusNoReg(value) {
  if (!REG_GS_CUS_NO.test(value)) return false
  else {
    // 判断年份
    let year_invalid = true;
    const reg_group = value.match(REG_GS_CUS_NO);
    // ['CUS202300013', 'CUS', '2023', '00013', index: 0, input: 'CUS202300013', groups: undefined]
    if (reg_group[2] >= 2021) year_invalid = false;
    if (year_invalid) {
      // 年份不合法
      return false;
    } else {
      return true;
    }
  }
}
/**
 * @租户 【客户编号】规则
 * @校验 格式是否合格，只能由字母和数字组成
 * @返回 true: 合格 / false: 不合格
**/
export function isCusNoReg(value) {
  if (!REG_CUS_NO.test(value)) return false
  else return true;
}
/**
 * @CRM 【客户编号】规则
 * @校验 格式是否合格，CUS+9位数字
 * @返回 true: 合格 / false: 不合格
**/
export function isCRMCusNoReg(value) {
  if (value && !REG_CRM_CUS_NO.test(value)) return false
  else return true;
}

/**
 * @高仙 【项目编号】规则
 * @校验 格式是否合格，只能由字母和数字组成
 * @返回 true: 合格 / false: 不合格
**/
export function isGSWkspaceNoReg(value) {
  if (!REG_GS_WKSPACE_NO.test(value)) return false
  else {
    // 判断年份
    let year_invalid = true;
    const reg_group = value.match(REG_GS_WKSPACE_NO);
    // ['KH20220000000', 'KH', '2022', '0000000', index: 2, input: 'ZCKH20220000000', groups: undefined]
    if (reg_group[2] >= 2021) year_invalid = false;
    if (year_invalid) {
      // 年份不合法
      return false;
    } else {
      return true;
    }
  }
}
/**
 * @租户 【项目编号】规则
 * @校验 格式是否合格，只能由字母和数字组成
 * @返回 true: 合格 / false: 不合格
**/
export function isWkspaceNoReg(value) {
  if (!REG_WKSPACE_NO.test(value)) return false
  else return true;
}

/**
 * @UDESK 【项目编号】规则
 * @校验 格式是否合格，必须以ZCKH或者KH开头；仅支持ZCKH/KH+年份+7位数字的组合（年份仅支持2021含2021之后的年份）
 * @返回 true: 合格 / false: 不合格
**/
export function isUDESKWkspaceReg(value) {
  // if (value && !REG_UDESK_WKSPACE_NO.test(value)) return false
  // else return true;
  if (!REG_GS_WKSPACE_NO.test(value)) return false
  else {
    // 判断年份
    let year_invalid = true;
    const reg_group = value.match(REG_GS_WKSPACE_NO);
    // ['KH20220000000', 'KH', '2022', '0000000', index: 2, input: 'ZCKH20220000000', groups: undefined]
    if (reg_group[2] >= 2021) year_invalid = false;
    if (year_invalid) {
      // 年份不合法
      return false;
    } else {
      return true;
    }
  }
}

export function getLocale() {
  let locale = localStorage.getItem('locale');
  // console.log('当前浏览器语言环境', navigator.language);
  const lang = navigator.language;
  if (!locale) {
    if (/^en/.test(lang)) {
      locale = 'en';
    } else if (/^zh.*CN$/.test(lang)) {
      locale = 'zh-CN';
    } else if (/^ko/.test(lang)) {
      locale = 'ko';
    } else if (/^ja/.test(lang)) {
      locale = 'ja-JP';
    } else {
      locale = 'en';
    }
    
  } else {
    locale = locale === 'cn' ? 'zh-CN' : locale
  }
  return locale;
}

export function transLocale(locale) {
  let _locale = '';
  if (locale) {
    if (/en/.test(locale)) {
      _locale = 'en';
    } else if (/cn|CN/.test(locale)) {
      _locale = 'zh-CN';
    } else if (/zh|ZH/.test(locale)) {
      _locale = 'zh-CN';
    } else {
      _locale = locale;
    }
  }
  return _locale;
}

export function transLocaleZH(locale) {
  let _locale = '';
  if (locale) {
    if (/cn|CN/.test(locale)) {
      _locale = 'zh-CN';
    } else if (/zh|ZH/.test(locale)) {
      _locale = 'zh-CN';
    } else {
      _locale = locale;
    }
  }
  return _locale;
}

// 兼容 locale 传给cloud 使用
export function transLocaleForCloud(locale) {
  let _locale = locale;
  if (locale === 'en') {
    _locale = 'en-US'
  }
  return _locale;
}

// 兼容 url locale
export function transUrlLocale(locale) {
  let _locale = locale;
  if (locale === 'en-US') {
    _locale = 'en';
  }
  return _locale;
}

// 性别翻译
export function transGender(val) {
  const _val = !!val ? val.toLowerCase() : '';
  switch (_val) {
    case 'female':
      return '女'

    case 'male':
      return '男'

    case 'privacy':
      return '保密'

    default:
      return '未设置'
  }
}

// 联系方式，去掉areaCode +8618701543923
export const phoneTrim = (phone, code) => {
  if (phone && code) {
    return phone.replace(`+${code}`, '');
  } else {
    return phone
  }
}

/**
 * @邮箱 掩码显示
 * 邮箱：tianddwanghl95@163.com
 * @描述 截取a字符前的字符串。mid = 字符串.length * 0.4，向下取整
 * @截取规则 从mid位置开始，截取mid位，替换成mid位的*字符串
 * tianddwanghl95 5 dwang *****
**/
export const hideEmailDetails = email => {
  if (typeof email === 'string' && String(email).indexOf('@') > 0) {
    let newEmail, arr = email.split('@'), _preStr = '';
    const len = arr[0].length * 0.4;
    const mid = Math.floor(len);
    const maskLen = mid;
    let star = '';

    // 生成*字符串
    for (let i = 0; i < maskLen; i++) {
      star += '*';
    }

    // 截取中间部分，从下标mid开始，截取mid位
    const piece = arr[0].substring(mid, (mid + maskLen));
    // console.log(arr[0], mid, piece, star)
    // 替换
    _preStr = arr[0].replace(piece, star);
    newEmail = _preStr + '@' + arr[1];
    return newEmail;
  } else {
    return email;
  }
}

/**
 * @手机号 掩码显示
 * @描述 mid = 字符串.length * 0.4, 向下取整
 * @截取规则 从mid位置开始，截取mid位，替换成mid位的*字符串
 * tianddwanghl95 5 dwang *****
**/
export const hidePhoneDetails = mobile => {
  if (typeof mobile === 'string' && mobile.length > 2) {
    let newMobile = '';
    const mid = Math.floor(mobile.length * 0.4);
    const maskLen = mid;
    let star = '';

    // 生成*字符串
    for (let i = 0; i < maskLen; i++) {
      star += '*';
    }

    // 截取中间部分，从下标mid开始，截取mid位
    const piece = mobile.substring(mid, (mid + maskLen));
    // console.log(mobile, mid, piece, star)
    // 替换
    newMobile = mobile.replace(piece, star);
    return newMobile;
  } else {
    return mobile;
  }
}

// 文本溢出
export const fontOverflow = (val, _length) => {
  if (!!val && val.length > _length) return `${val.substring(0, _length)}...`
  else return val;
}

/**
 * 角色翻译
 * key: alise | code
**/
class TransRole {
  title (key) {
    return lang[`ROLE_${key}`] || '';
  }

  desc (key) {
    return lang[`ROLE_DES_${key}`] || '';
  }
}
export const transRole = new TransRole();

/**
 * 运维大区 转换方法
 * 
**/
class TransRegions {
  // v1版本
  getV1Map (regionsV3) {
    // key: regionCode
    const regions_map = {};
  
    regionsV3.forEach(region => {
      const { regionCode } = region;
      regions_map[regionCode] = region;
    });

    return regions_map;
  }
}
export const transRegions = new TransRegions();

/**
 * @日期
**/
class DateFormat {
  common (_time) {
    if (_time) return moment(_time).format(DATE_FORMAT)
    else return _time
  }

  // ms级时间戳
  formatUnixTimeStamp (_time) {
    // '0'字符串情况
    return Number(_time) ? moment(Number(_time)).format(DATE_FORMAT) : '-'
  }
}
export const dateFormat = new DateFormat();

export function encodeObjectValues(obj) {
  const encodedObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === 'object' && value !== null) {
        encodedObj[key] = encodeObjectValues(value); // 递归处理嵌套对象
        if (Array.isArray(value) ) {
          if (value.length) {
            encodedObj[key] = value; // 数组项单独处理，保留原值
          } else {
            // 空数组筛选需要删除，待考究，是否应用于所有的get请求场景
            delete encodedObj[key]
          }
        }
      } else {
        if (value === null || value === undefined) {
          // 非null参数参与编码，否则传参错误。
          delete encodedObj[key]
        } else {
          encodedObj[key] = encodeURIComponent(value); // 对值进行编码
        }
      }
    }
  }

  return encodedObj;
}

export function encodeParams(obj) {
  const encodeObj = encodeObjectValues(obj);
  let resultMap = [];
  Object.keys(encodeObj).forEach((key) => {
    if (Array.isArray(encodeObj[key])) {
      resultMap = [...resultMap, ...encodeObj[key].map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)]
    } else {
      resultMap.push(`${encodeURIComponent(key)}=${encodeObj[key]}`)
    }
  });

  return resultMap.join('&')
}

// 近 number 天起始时间戳
export function getTimeStampsForLastNDays(number) {
  const endDate = moment().endOf('day');
  // 获取七天前的日期
  const startDate = moment().subtract(number, 'days').startOf('day');

  // 获取时间戳（毫秒数）
  const startTimestamp = startDate.valueOf();
  const endTimestamp = endDate.valueOf();

  return { startTimestamp, endTimestamp }
}