/**
 * api config
 * name: 环境名称
 * domainName: home域名
 * cedarApiUrl: api地址
 * serviceUrl: 语言包地址
 * homeUrl: 本站点访问地址
 * mobileDomain: H5版访问地址
 * gsKey: gs标识
 * pubKey: 登录加密公钥
 * appId: 预留字段，用于第三方登录，暂未使用
 * assets: 静态资源地址
 */
 export const PROXY_ENV = process.env.PROXY_ENV;
 export const REGION = process.env.REGION;
 export const regionValue = REGION ? `.${REGION}` : ''; // region存在时，api里插入region
 export const PORT = process.env.PORT || '1234';
 export const ENV_CONFIG = {
  production: {
    name: '正式环境',
    domainName: `https://home${regionValue}.gs-robot.com`,
    mobileDomain: `https://home${regionValue}.gs-robot.com/apps-mobile/#`,
    cedarApiUrl: `https://bot${regionValue}.gs-robot.com`,
    serviceUrl: `https://service${regionValue}.gs-robot.com`,
    homeUrl: `https://home${regionValue}.gs-robot.com`,
    env: 'prod',
    appId: 'wx54252986794676b8',
    gsKey: '9acdd419-3724-11ec-9b3d-00163e044ac5',
    pubKey: 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FEU0VaT2FPdUNhaFFHTzVNaTB4SityUWo3dApZUmxPbVBmSFJsYXB3MDl5RjlPNmVWVExmNTJIQ0dVOXhqK1NzMmpCTW5BQnRCRTFiclU1TnNFQTlueHViUEZaCkVEK3JQZTFwWkZ0d1dYOGRVWlpXeXVjSWdPdGowUVlpejlmeTdUa1NXVWdlK0lGZmZmc0ZuTzBNbm1YVWtNNEsKOEZoK3JuZTNtSWRoNzNvTE93SURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ=='
  },
  prod: {
    name: '正式环境',
    domainName: `https://home${regionValue}.gs-robot.com`,
    mobileDomain: `https://home${regionValue}.gs-robot.com/apps-mobile/#`,
    cedarApiUrl: `https://bot${regionValue}.gs-robot.com`,
    serviceUrl: `https://service${regionValue}.gs-robot.com`,
    homeUrl: `https://home${regionValue}.gs-robot.com`,
    env: 'prod',
    appId: 'wx54252986794676b8',
    gsKey: '9acdd419-3724-11ec-9b3d-00163e044ac5',
    pubKey: 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FEU0VaT2FPdUNhaFFHTzVNaTB4SityUWo3dApZUmxPbVBmSFJsYXB3MDl5RjlPNmVWVExmNTJIQ0dVOXhqK1NzMmpCTW5BQnRCRTFiclU1TnNFQTlueHViUEZaCkVEK3JQZTFwWkZ0d1dYOGRVWlpXeXVjSWdPdGowUVlpejlmeTdUa1NXVWdlK0lGZmZmc0ZuTzBNbm1YVWtNNEsKOEZoK3JuZTNtSWRoNzNvTE93SURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ=='
  },
  release: {
    name: '预发布环境',
    domainName: 'https://home.release.gs-robot.com',
    mobileDomain: 'https://home.release.gs-robot.com/apps-mobile/#',
    cedarApiUrl: 'https://bot.release.gs-robot.com',
    serviceUrl: 'https://service.release.gs-robot.com',
    homeUrl: 'https://home.release.gs-robot.com',
    env: 'release',
    appId: 'wx8b87d80836c5c088',
    gsKey: 'c8f092ee-46d9-11ec-84d5-00163e25fdf6',
    pubKey: 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FERkRURVZKS0orSEgxaHViclQrRFJ6SVZsRApqbzVqaGh2ZXNCaE1kUEw4WnhiSVMvdHJmaGQyNzVCOHVtdVpZbWZEK2xLblp5TnExNk5uQmhxVmswd1FKa2cxClA5ZUFicHhWaFVYMm1ObGUrL1FqUTRpZjdsNmJqL1l2MXpJbUd3dkFBK3B2ak5tL0lhb1V5RGNySHVxL2NPK0UKTkxEa0JGY0xRUlpLd2I4eEt3SURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==',
  },
  test: {
    name: '测试环境',
    domainName: 'https://home.test.gs-robot.com',
    mobileDomain: 'https://home.test.gs-robot.com/apps-mobile/#',
    cedarApiUrl: 'https://bot.test.gs-robot.com',
    serviceUrl: 'https://service.test.gs-robot.com',
    homeUrl: 'https://home.test.gs-robot.com',
    env: 'test',
    appId: 'wxd331e76a834f4d61',
    gsKey: '096233c3-1f92-11ec-ae2f-00163e1c1662',
    pubKey: 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FERkRURVZKS0orSEgxaHViclQrRFJ6SVZsRApqbzVqaGh2ZXNCaE1kUEw4WnhiSVMvdHJmaGQyNzVCOHVtdVpZbWZEK2xLblp5TnExNk5uQmhxVmswd1FKa2cxClA5ZUFicHhWaFVYMm1ObGUrL1FqUTRpZjdsNmJqL1l2MXpJbUd3dkFBK3B2ak5tL0lhb1V5RGNySHVxL2NPK0UKTkxEa0JGY0xRUlpLd2I4eEt3SURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==',
  },
  dev: {
    name: '开发环境',
    domainName: 'https://home.dev.gs-robot.com',
    mobileDomain: 'https://home.dev.gs-robot.com/apps-mobile/#',
    cedarApiUrl: 'https://bot.dev.gs-robot.com',
    serviceUrl: 'https://service.dev.gs-robot.com',
    homeUrl: 'https://home.dev.gs-robot.com',
    env: 'dev',
    appId: 'wx22f36dc4a378d481',
    gsKey: '9acdd419-3724-11ec-9b3d-00163e044ac5',
    pubKey: 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FERkRURVZKS0orSEgxaHViclQrRFJ6SVZsRApqbzVqaGh2ZXNCaE1kUEw4WnhiSVMvdHJmaGQyNzVCOHVtdVpZbWZEK2xLblp5TnExNk5uQmhxVmswd1FKa2cxClA5ZUFicHhWaFVYMm1ObGUrL1FqUTRpZjdsNmJqL1l2MXpJbUd3dkFBK3B2ak5tL0lhb1V5RGNySHVxL2NPK0UKTkxEa0JGY0xRUlpLd2I4eEt3SURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==',
  },
  local: {
    name: '本地环境',
    domainName: `https://home.local.gs-robot.com:${PORT}`,
    mobileDomain: `https://home.${PROXY_ENV}.gs-robot.com:${PORT}/apps-mobile/#`,
    cedarApiUrl: `https://bot.${PROXY_ENV}.gs-robot.com`,
    serviceUrl: `https://home.local.gs-robot.com:${PORT}/i18n`,
    homeUrl: `https://home.local.gs-robot.com:${PORT}`,
    env: 'local',
    appId: 'wx22f36dc4a378d481',
    gsKey: '096233c3-1f92-11ec-ae2f-00163e1c1662',
    pubKey: 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHZk1BMEdDU3FHU0liM0RRRUJBUVVBQTRHTkFEQ0JpUUtCZ1FERkRURVZKS0orSEgxaHViclQrRFJ6SVZsRApqbzVqaGh2ZXNCaE1kUEw4WnhiSVMvdHJmaGQyNzVCOHVtdVpZbWZEK2xLblp5TnExNk5uQmhxVmswd1FKa2cxClA5ZUFicHhWaFVYMm1ObGUrL1FqUTRpZjdsNmJqL1l2MXpJbUd3dkFBK3B2ak5tL0lhb1V5RGNySHVxL2NPK0UKTkxEa0JGY0xRUlpLd2I4eEt3SURBUUFCCi0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQ==',
  },
  selected: 0,
  assets: 'https://gs-robot-static-resource.oss-cn-shanghai.aliyuncs.com/public/web/apps/assets'
};

// 默认为 development 环境
let env = process.env.PROXY_ENV || 'dev';

if (process.env.NODE_ENV === 'development') {
  console.log('PROXY_ENV===', process.env.PROXY_ENV)
  env = 'local'
  console.log('env ===', env)
  ENV_CONFIG['local'].gsKey = ENV_CONFIG[PROXY_ENV].gsKey
}
export const envConfig = env;
// export const envConfig = 'local';
const envAPI = {
  cedarApiUrl: ENV_CONFIG[envConfig].cedarApiUrl,
  serviceUrl: ENV_CONFIG[envConfig].serviceUrl,
  domainUrl: ENV_CONFIG[envConfig].domainName,
  mobileDomain: ENV_CONFIG[envConfig].mobileDomain,
  homeUrl: ENV_CONFIG[envConfig].homeUrl,
  gsKey: ENV_CONFIG[envConfig].gsKey,
  pubKey: ENV_CONFIG[envConfig].pubKey,
  assets: ENV_CONFIG.assets,
  region: REGION
};

export default envAPI;