import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Layout, Menu, Avatar, Modal, Button } from "antd";
import {
  UserOutlined,
  AppstoreOutlined,
  ApartmentOutlined,
  RobotOutlined,
  IdcardOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShopOutlined,
  AuditOutlined
} from "@ant-design/icons";
import { IconCustomer } from "src/component/IconCustom";
import { logout, fontOverflow, getLocale } from "src/libs/utils";
import IconGasUser from "src/component/IconGasUser";
import { GlobalContext } from "src/view/reducer";
import Watermark from "src/component/WaterMark";

import LangMenu from "./component/LangMenu";
import Brand from "./component/Brand";
import "src/scss/common.scss";
import "src/scss/main.scss";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const MainLayout = (props) => {
  const history = useHistory();

  const { children } = props;
  // menu key 对应 路由path
  const routes = {
    app_center: "/app/center/home",
    // 用户
    user_all: "/app/user/home",
    user_group: "/app/group/home",
    device_group: "/app/device-group/home",
    bind_robot: "/app/user/bind-robot",
    // 策略
    app1_policy: "/app/policy/home",
    // 角色
    access_role: "/app/role/home",
    // 集团视图
    group_company: "/app/group-company/home",
    // 客户
    customer_center: "/app/customer/home",
    // 组织
    organization: "/app/organization/home",
    // 终端项目
    workspace: "/app/workspace/home",
    // 我的机器人
    my_devices: '/app/devices/home',
    // 机器清单
    inventory: '/app/inventory/home',
    // 应用
    robot_report: "/app/center/robotbi", // /app/center/:id
    aiot: "/app/center/aiot",
    // 我的资料
    user_profile: "/app/settings/myprofile",
    // authorization: "/app/authorization/home",
    approval_center: "/app/approval-center/home",
    application_record: "/app/application-record/home"
  };

  // 当前租户
  const { user, userLocale, activeMenu, access, dispath, customer } = useContext(
    GlobalContext
  );
  // 左菜单是否显示
  const isSiderShow =
    access.canIAM ||
    access.canCustomer ||
    access.canOrg ||
    access.canTenantWorkspace;
  // 当前高亮菜单
  const [activeKey, setActiveKey] = useState("");
  const [realname, setRealname] = useState(lang.昵称);

  // 非本租户，不可操作【我的资料】
  const localTenant = $session.sessionget("view.layout.selected.tenant");
  const [isProfileEnable, setIsProfileEnable] = useState(
    localTenant && localTenant.id ? false : true
  );

  // 退出确认
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  // 左菜单收起
  const [collapsed, setCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  // 退出 确认
  const handleOk = () => {
    setIsConfirmVisible(false);
    logout();
  };

  // 退出 取消
  const handleCancel = () => {
    setIsConfirmVisible(false);
  };

  // 获取验证规则
  const getVelidate = () => {
    let _obj = {};
    const getAppRules = () => import("../libs/velidate/appCenter.json");
    const getTenantRules = () => import("../libs/velidate/tenantForm.json");
    getAppRules().then((res) => {
      _obj["appCenter"] = res;
    });
    getTenantRules().then((res) => {
      _obj["tenantForm"] = res;
    });
    dispath({ type: "UPDATE_VELIDATE_RULES", data: _obj });
  };

  const clearSession = () => {
    // 组织的缓存
    $session.remove("view.organization.search");
    $session.remove("view.org.page");

    $session.remove("view.workspace.page");
    $session.remove("view.workspace.search");

    $session.remove("view.customer.page");
    $session.remove("view.customer.search");

    $session.remove("view.customer.workspace.page");
    $session.remove("view.customer.workspace.search");
  };

  // path push
  const goto = (key) => {
    clearSession();

    if (routes.hasOwnProperty(key)) {
      $storage.set("layout.menu.active", key);
      dispath({ type: "UPDATE_ACTIVE_MENU", data: key });
      setActiveKey(key);
      history.push({ pathname: routes[key] });
    } else {
      // 左导航高亮，上次记录的key
      setActiveKey($storage.get("layout.menu.active"));
    }
  };

  // 左导航点击事件
  const handleMenuClick = (item) => {
    const { key } = item;
    goto(key);
  };

  // 我的 event
  const handleHeaderMenuClick = ({ key, domEvent }) => {
    // debugger;
    switch (key) {
      // 我的资料
      case "user_profile":
        if (isProfileEnable) {
          goto(key);
        } else {
          alert(
            "请切回本公司，再操作！Please switch to your company, and try again!"
          );
        }
        break;

      // 退出
      case "sub_user-signOut":
        setIsConfirmVisible(true);
        break;

      // 应用中心
      case "app_center":
        goto(key);
        break;

      default:
        break;
    }
  };

  // 缓存语言：如果本地未缓存语言，则缓存语言；
  const cacheLocale = () => {
    const _locale = $storage.get("locale");
    if (!_locale) {
      const _locale_ = userLocale || getLocale();
      $storage.set("locale", _locale_);
    }
  };

  // 左菜单折叠/展开
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(
    () => {
      if (user) setRealname(user?.displayName);
    },
    [user]
  );

  useEffect(() => {
    const _pathname = history.location.pathname;
    // 如果初始路由不在 左导航则不跳转，解决非菜单路由刷新 不停留问题
    if (_pathname === routes[activeMenu]) {
      goto(activeMenu);
    } else {
      // 左导航高亮，上次记录的key
      if (_pathname !== "/app/center/home") {
        setActiveKey($storage.get("layout.menu.active"));
      } else {
        setActiveKey("app_center");
      }
    }

    getVelidate();
    // 缓存语言
    // cacheLocale();
  }, []);

  useEffect(() => {
    setActiveKey(activeMenu)
  }, [activeMenu])

  useEffect(
    () => {
      const _menu_items = [];

      const useItems = {
        label: lang.用户中心 || "用户中心",
        // key: "user_all",
        icon: <UserOutlined />,
        children: []
      }

      // 用户管理
      if (access.canIAM || access.canUserManage) {
        useItems.children.push({
          label: lang.用户管理 || "用户管理",
          key: "user_all",
          // icon: <UserOutlined />,
        })
      }

      // 角色管理
      if (access.canIAM) {
        useItems.children.push({
          label: lang.角色管理 || "角色管理",
          key: "access_role",
          // icon: <IdcardOutlined />,
        });
      }

      // 绑定机器
      useItems.children.push({
        label: lang.绑定机器 || "绑定机器",
        key: "bind_robot",
      })

      _menu_items.push(useItems);

      // 客户管理
      if (access.isGSUser) {
        const _item = {
          label: lang.客户中心 || "客户中心",
          key: "customer_manage",
          icon: <IconCustomer />,
          children: [],
        };

        if (access.isSuperAdmin || access.canCompanyGroup) {
          _item["children"].push({
            label: <span title={lang.集团视图 || "集团视图"}>{lang.集团视图 || "集团视图"}</span>,
            key: "group_company",
          });
        }

        if (access.canCustomer) {
          _item["children"].push({
            label: <span title={lang.客户管理 || "客户管理"}>{lang.客户管理 || "客户管理"}</span>,
            key: "customer_center",
          });
        }

        _item.children?.length && _menu_items.push(_item);
      } else {
        if (access.canCustomer) {
          _menu_items.push({
            label: lang.客户中心 || "客户中心",
            key: "customer_center",
            icon: <IconCustomer />,
          });
        }
      }

      // 组织管理
      if (access.canOrg) {
        _menu_items.push({
          label: lang.我的组织 || "我的组织",
          key: "organization",
          icon: <ApartmentOutlined />,
        });
      }

      if (access.canTenantWorkspace) {
        _menu_items.push({
          label: lang.终端项目 || "终端项目",
          key: "workspace",
          icon: <ShopOutlined />,
        });
      }

      if (access.canInventory) {
        _menu_items.push({
          label: lang.机器管理 || "机器管理",
          key: "inventory",
          icon: <RobotOutlined />,
        });
      }

      if (access.canApplyRecords || access.canIAM) {
        const _item = {
          label: lang.授权管理,
          key: "authorization",
          icon: <AuditOutlined />,
          children: [],
        };

        console.log('customer', customer)

        if ((access.canApprovalCenter || access.canIAM) && customer?.isManualApproval) {
          _item["children"].push({
            label: <span title={lang.审批中心}>{lang.审批中心}</span>,
            key: "approval_center",
          });
        }

        _item["children"].push({
          label: <span title={lang.申请记录}>{lang.申请记录}</span>,
          key: "application_record",
        });

        _item.children?.length && _menu_items.push(_item);
      }

      // 文字过长悬浮显示全文
      setMenuItems(_menu_items.map(item => {
        return {
          ...item,
          label: <div style={{
            textOverflow: 'ellipsis',
            overflow: "hidden"
          }} title={item.label}>{item.label}</div>
        }
      }));
    },
    [access, customer]
  );

  // 左侧菜单
  const sider = (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="site-layout-background"
    >
      <Menu
        mode="inline"
        selectedKeys={activeKey}
        style={{ height: "100%", borderRight: 0 }}
        triggerSubMenuAction="click"
        onClick={handleMenuClick}
        defaultOpenKeys={["customer_manage"]}
        items={menuItems}
      />

      {/* 左菜单收起/展开 */}
      <div className="gs-menu-collapse">
        {collapsed ? (
          <MenuUnfoldOutlined
            className="collapse-i"
            onClick={() => {
              handleCollapse();
            }}
          />
        ) : (
          <MenuFoldOutlined
            className="collapse-i"
            onClick={() => {
              handleCollapse();
            }}
          />
        )}
      </div>
    </Sider>
  );

  return (
    <Layout className="main-container">
      <Watermark watermarkText={user.username || user.displayName || user.email} />
      <Header className="header-blue">
        <Brand setIsProfileEnable={setIsProfileEnable} />
        <Menu
          theme="dark"
          mode="horizontal"
          className="menu-blue menu-right"
          selectable={false}
          selectedKeys={activeKey}
          onClick={handleHeaderMenuClick}
        >
          <LangMenu />
          <SubMenu
            key="sub_user"
            title={
              fontOverflow(realname, 15) || fontOverflow(user.username, 15)
            }
            icon={
              <Avatar
                src={<IconGasUser user={user} />}
                style={{ backgroundColor: "#F7F7F7" }}
              />
            }
          >
            <Menu.Item key="sub_user-signOut">{lang.退出}</Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
      <Layout>
        <Layout style={{ padding: "0" }}>
          <Content
            className="site-layout-background"
            style={{
              margin: "0",
              height: "100%",
              overflow: "auto",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>

      <Modal
        title={lang.退出确认}
        okText={lang.确定}
        cancelText={lang.取消}
        open={isConfirmVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <p>{lang.确定要退出吗}？</p>
      </Modal>
    </Layout>
  );
};

export default MainLayout;
