import React, { useState, useEffect, useContext, useCallback } from "react";
import { GlobalContext } from "src/view/reducer";
import { Login } from "src/api/Auths";
import { checkRole, access } from "src/access";
import { transLocale, clearAccess } from "src/libs/utils";
import { initI18n } from "src/i18n/index";
import { personApi } from "src/api/Auths";
import { appUserApi } from "../view/user/_api";
import { SERVICES } from "src/libs/configData";

export const useUpdateMe = (state) => {
  const ownContext = $session.sessionget("view.layout.own.context");
  // debugger;
  const loginApi = new Login();
  const { dispath } = useContext(GlobalContext);
  const [updateRes, setUpdateRes] = useState(state);

  //获取已有角色列表
  const getUserBusinessRoles = async (person_id) => {
    const res = await appUserApi.get({
      person_id,
    });
    if (res?.roles) return Promise.resolve(res.roles);
    else return Promise.reject();
  };

  // 获取用户的功能权限树
  const getUserPermissions = async (params) => {
    const res = await personApi.getIAMPermissions(params);
    if (res?.permissions) return Promise.resolve(res.permissions);
    else return Promise.reject();
  };

  // 获取应用权限
  const getApplicationPermissions = async (user) => {
    const res = await personApi.associatedApplications({
      personId: user?.id,
    });
    dispath({ type: "UPDATE_APPLICATION_PERMISSIONS", data: res })
  }

  // 获取用户信息
  const getUserContext = useCallback(
    async () => {
      if (updateRes) {
        try {
          await loginApi.getMe().then((res) => {
            const { code, tenant, person, roles, error, customer, internalPerson } = res;
            if (!internalPerson) {
			  clearAccess();
              console.log('SERVICES.gsCloudUrl.href', SERVICES.gsCloud.href)
              window.location.href = SERVICES.gsCloud.href;
			  return;
            };
            if (!code && tenant && person) {
              dispath({ type: "UPDATE_CURRENT_USER", data: person });
              dispath({ type: "UPDATE_CURRENT_TENANT", data: tenant });
              dispath({ type: "UPDATE_CURRENT_ROLES", data: roles });
              dispath({ type: "UPDATE_CUSTOMER", data: customer });
              dispath({ type: "UPDATE_INTERNAL_PERSON", data: internalPerson });

              // 保存用户自己的语言
              const { locale } = person;
              if (locale) {
                const _locale = transLocale(locale);
                dispath({ type: "UPDATE_USER_LOCALE", data: _locale });
              }

              // 获取业务角色
              getUserBusinessRoles(person.id).then((businessRoles) => {
                // 获取本应用的权限输入
                getUserPermissions({
                  tenantId: tenant.id,
                  personId: person.id,
                }).then((permissions) => {
                  const _access = access({
                    roles,
                    tenant,
                    businessRoles,
                    permissions,
                  });

                  // 更新权限
                  dispath({ type: "UPDATE_ACCESS", data: _access });
                });
              });

              getApplicationPermissions(person);

              // 本地不存在语言
              const _locale = $storage.get("locale");
              if (!_locale) {
                $storage.set("locale", transLocale(locale));
                // 语言包初始化之后，需要reload一下，否则header 组件拿不到最新的locale
                initI18n().then((_) => {
                  window.location.reload();
                });
              }

              if (roles) {
                const isAdmin = checkRole.isTanentAdmin(roles);
                // 是否管理员
                dispath({
                  type: "UPDATE_CURRENT_USER_IS_ADMIN",
                  data: isAdmin,
                });
              }
            }
            setUpdateRes(false);
          });
        } catch (error) {
          setUpdateRes(false);
        }
      } else {
        return;
      }

      // 获取当前用户信息
    },
    [dispath, loginApi.getMe, updateRes, setUpdateRes]
  );

  useEffect(
    () => {
      if (updateRes) {
        // 更新用户信息
        getUserContext();
      }
    },
    [updateRes]
  );

  return [updateRes, setUpdateRes];
};
